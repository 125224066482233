
.footer-logo {

  p {

    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;

    max-width: 250px;
    margin-top: 30px;

    @media only screen and (max-width: 991px) {
      margin: 30px auto;
      max-width: 350px;
    }
    @media only screen and (max-width: 767px) {

      font-size: 14px;
    }

  }

  @media only screen and (max-width: 991px) {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #757575;
  }
  @media only screen and (max-width: 767px) {

    border-bottom: 1px solid #757575;

  }
}

.footer-content {

  span {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;

    color: #323D47;
    @media only screen and (max-width: 767px) {

      font-size: 14px;
    }
  }
  & > div {
    max-width: 200px;
    @media only screen and (max-width: 767px) {

      padding: 20px 0;
      width: 100%;
      max-width: 100%;
      border-bottom: 1px solid #757575;

    }

  }
  & > div:first-child{
    ul{
      line-height: 1rem;
    }
  }
  div.social-content {
    a {
      font-size: 35px;

      color: #757575;
      transition: color ease-in-out;
      &:hover{
        color: darken(#757575, 10%);
      }

      i.fa-facebook {
        color: #0E7BF9!important;
        transition: color ease-in-out;
        &:hover{
          color: darken(#0E7BF9, 10%)!important;
        }
      }
      i.fa-linkedin {
        color: #0C65C0!important;
        transition: color ease-in-out;
        &:hover{
          color: darken(#0C65C0, 10%)!important;
        }
      }
    }
    @media only screen and (max-width: 767px) {

      border-bottom: none;

    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height:normal;
    text-transform: capitalize;
    @media only screen and (max-width: 767px) {
      font-size: 14px;
    }
    li {
      margin-top: 20px;
      a {
        color: #757575;
      }
    }
  }
}

.footer-copyright {
  border-top: 1px solid #f1f2f3;
}

/*----------------*/
