// Write your custom style. Feel free to split your code to several files
body {
  font-family: 'Noto Sans', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Muli', sans-serif;
  font-weight: 900;
}

h4.pre-list {
  padding-left: 23px;
}

.bg-gray {
  background-color: $color-bg-gray;
}

.bg-blue {
  background-color: $color-bg-blue;
}

.bg-green {
  background-color: $green;

  &.button--moema:hover {
    background-color: #90a829
  }
}

.home-header {
  .text-primary {
    color: $green !important;
  }
}

.section.industry {
  .nav {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    &.nav-tabs-shadow {
      flex-direction: row;

      .nav-item {
        width: 33%;
        @media only screen and (max-width: 991px) {
          width: 50%;
        }
        @media only screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }
  }
}

.section_book {
  position: relative;

  h2 {
    margin-bottom: 30px;
  }

  h3 {
    margin-bottom: 30px;
  }

  #anchor_book {
    position: absolute;
    left: 0px;
    top: -150px;
  }
}

.form_book {
  padding: 30px;

  .gform_footer {
    input[type="submit"] {
      float: none;
      color: #fff;
      background-color: $blue;
      margin-top: 30px;
    }

    &.top_label {
      text-align: center;
    }
  }
}

.tab-industry {
  &.tab-content {
    .active {
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: contain;
    }

    .text_wrap {
      color: $white;
      max-width: 75%;
      z-index: 1;
      padding: 80px 20px 60px;
      @media only screen and (max-width: 767px) {
        max-width: 90%;
        padding: 50px 0 30px;
      }
    }
  }
}

.custom-top-nav {
  z-index: 1001;
  width: 100%;

  .topbar, .navbar {
    position: relative;
  }

  @media only screen and (max-width: 992px) {
    .navbar {
      position: absolute;

      .logo-dark {
        width: 50%;
      }
    }
  }
  @media only screen and (max-width: 1199px) {
    .navbar {
      .logo-dark {
        width: 150px;
      }
    }
  }

  .topbar {
    display: none !important;

    .social {
      width: 100%;
      text-align: right;
    }
  }

  .navbar {
    background: $white;
    padding-top: 15px;
    padding-bottom: 15px;
    @media (min-width: 992px) {
      top: initial !important;
    }

    .nav-link {
      line-height: 85px;
    }

    .bg-contact {
      background: $green;

      &:hover {
        background: #90a829;
      }
    }

    .backdrop-navbar {
      background-color: transparent;
    }
  }

  .navbar-mobile {
    .close-navbar {
      text-align: right;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      cursor: pointer;
    }
  }
}

header.header {
  margin-top: 119px;
  @media only screen and (max-width: 991px) {
    margin-top: 100px;
  }
}

.mobile-lang {
  margin-left: 10px;
  @media only screen and (max-width: 991px) {
    margin-left: 0;
    margin-top: 20px;
    display: block;
  }
}

ul#my_main_menu {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  li {
    &.current-menu-item a {
      color: #000;
    }

    &:first-child {
      a {
        padding-left: 0;
      }
    }

    &:last-child {
      a {
        padding-right: 0;
      }
    }

    @media only screen and (max-width: 991px) {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    }

    a {
      line-height: 85px;
      color: rgba(117, 117, 117, 0.8);
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 0.87em;
      word-spacing: 2px;
      padding-left: 0.5rem;
      padding-right: 0.5rem;

      &:hover {
        color: #000;
      }

      @media only screen and (max-width: 991px) {
        min-height: inherit;
        line-height: inherit;
        font-size: 0.95rem;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    display: block;
  }
}

.header {
  margin-top: 159px; /*100px*/
  padding-top: 200px; /*150px*/
  padding-bottom: 200px; /*150px*/
  /*&.about-page{
    padding-bottom: 330px;
    @media only screen and (max-width: 1200px) {
      padding-top: 200px;
      padding-bottom: 200px;
    }
    @media only screen and (max-width: 991px) {
      padding-top: 100px;
      padding-bottom: 100px;
    }
    @media only screen and (max-width: 480px) {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }*/
  @media only screen and (max-width: 991px) {
    margin-top: 100px;
  }

  &.banner_ebook {
    padding: 10rem 0 1rem;

    @media only screen and (max-width: 991px) {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }
  }
}

.section {
  padding-top: 4rem;
  padding-bottom: 6rem;

  &:last-child {
    padding-bottom: 4rem;
  }

  @media only screen and (max-width: 767px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .section-header {
    hr {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }

  .init_color {
    color: initial;
  }

  .badge-warning {
    background-color: $green;

    &:hover, &:focus {
      background-color: #90a829;
    }
  }
}

.rounded-20 {
  border-radius: 20px;
}

.text-20 {
  font-size: 20px;
}

.text-big {
  font-size: 50px;
  line-height: 50px;
}

.white {
  color: #fff;
}

.fit-content {
  width: fit-content;
}


@media only screen and (max-width: 992px) {
  .no-pl-6-lg {
    padding-left: 1rem !important;
  }
  .no-pr-6-lg {
    padding-right: 1rem !important;
  }

}

.notoSans{
  font-family: 'Noto Sans', sans-serif;
}

@media only screen and (max-width: 480px) {
  .blog-filter h2{
    font-size: 30px;
  }

}

.bg-green {
  background-color: $green;
}

.priority_job {
  .secteur {
    background-color: #b4d234;
    padding: .25em .5em;
    border-radius: 2px;
    color: #FFF;
    margin-bottom: 5px;
    text-align: center;
  }

  .card-body {
    p, span {
      color: #757575;
    }

    h5 {
      color: #323d47;
    }
  }
}

.list_book {
  .item_book {
    padding-top: 20px;
    padding-bottom: 50px;
    border-bottom: 2px solid $blue;

    .custom-btn {
      margin-left: 0;
    }
  }
}

.list_book {
  .col-12:last-child .item_book {
    border: 0;
  }
}

.custom-btn {
  position: relative;
  margin: 1em;
  // z-index:1;
}

.card-box {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;

  .w-box-3 {
    width: 31.33%;
    margin: 1%;
    @media only screen and (max-width: 767px) {
      width: 47.33%;
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
      margin-bottom: 12px;
    }

    .card-title {
      text-align: center;
    }
  }

  .w-box-4 {
    width: 22%;
    margin: 0px 6px 10px;
    @media only screen and (max-width: 991px) {
      width: 47.33%;
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
      margin: 10px;
    }
  }
}

.icon-passif:before {
  content: url(/wp-content/themes/parkour3/img/icons/candidat_icon_passif.png);
}

.icon-actif:before {
  content: url(/wp-content/themes/parkour3/img/icons/candidat_icon_actif.png);
}

.icon-collaborate:before {
  content: url(/wp-content/themes/parkour3/img/icons/candidat_icon_collaboratif.png);
}

/*CSS formulaires*/
#postes_a_combler:target:before {
  content: "";
  display: block;
  height: 180px; /* fixed header height*/
  margin: -180px 0 0; /* negative fixed header height */
  @media only screen and (max-width: 991px) {
    height: 100px;
    margin: -100px 0 0;
  }
}

/*.form-control,*/

form {

  .gform_body {
    label {
      display: none;
    }

    ul {
      padding-inline-start: 0px;

      &.gform_fields {
        list-style-type: none;

        .contact-upload {
          .ginput_container.ginput_container_fileupload {
            button.browse-btn {
              background-color: #f7f7f7;
              color: #999;
              border: 1px solid #eaeff4;
            }
          }
        }

        .ginput_container {
          margin-bottom: 10px;

          input {
            display: block;
            width: 100%;
            height: 50px;
            /*height: calc(2.53125rem + 2px);*/

            padding: 0 15px;
            font-size: .9375rem;
            font-weight: 300;
            line-height: 1.9;
            color: #999;
            background-color: #f7f7f7;
            background-clip: padding-box;
            border: 1px solid #eaeff4;
            -webkit-transition: all 0.3s ease-out;
            transition: all 0.3s ease-out;
          }

          @mixin placeholder {
            ::-webkit-input-placeholder {
              @content
            }
            :-moz-placeholder {
              @content
            }
            ::-moz-placeholder {
              @content
            }
            :-ms-input-placeholder {
              @content
            }
          }
          @include placeholder {
            color: rgba(153, 153, 153, 0.6);
          }

          &.ginput_container_fileupload {
            position: relative;
            overflow: hidden;
            display: inline-block;

            button.browse-btn {
              border: 1px solid #f7f7f7;
              color: #999;
              background-color: white;
              padding: 6px 20px;
              margin-right: 10px;
              border-radius: 10rem;
            }

            input {
              // padding:0;
              // background-color:transparent;
              // border:none;
              background-color: #f7f7f7;
              font-family: inherit;
              font-size: 100px;
              position: absolute;
              left: 0;
              top: 0;
              opacity: 0;
            }
          }

          &.ginput_container_textarea {
            textarea {
              width: 100%;
              height: 150px;
              border-color: #eaeff4;
              padding: 0 15px;
              background-color: #f7f7f7;
            }
          }
        }

        .w-half {
          float: left;
          width: 48%;
          margin-right: 4%;
          @media only screen and (max-width: 767px) {
            width: 100%;
            margin-right: 0
          }

          &:nth-child(even) {
            margin-right: 0
          }
        }
      }
    }
  }

  .gform_footer {
    input[type="submit"] {
      float: right;
      font-weight: 600;
      border: 1px solid transparent;
      line-height: 2.2;
      letter-spacing: 1.7px;
      text-transform: uppercase;
      color: #757575;
      background-color: #fff;
      padding: 7px 32px 6px;
      font-size: 12px;
      border-radius: 10rem;
      cursor: pointer;
    }

    @media only screen and (max-width: 991px) {
      &.top_label {
        text-align: center;
      }
    }
  }

  #gform_wrapper_5, #gform_wrapper_6 {
    .gform_footer {
      input[type="submit"] {
        float: left;
        background-color: #0b809a;
        color: rgba(255, 255, 255, 0.85) !important;
        @media only screen and (max-width: 991px) {
          float: none;
        }
      }
    }
  }

  &.poste_filter {
    margin-bottom: 40px;

    .category {
      display: block;
      padding: 0 0 5px 5px;
      color: #323d47;
    }

    .flex-end {
      align-self: flex-end;
    }

    .reset_underlined {
      margin-bottom: 6px;
      text-decoration: underline;
    }

    select {
      height: 50px;
      border: 1px solid #eaeff4;
      padding: 0 15px;
      width: 100%;
      color: #323d47;
      background: rgba(255, 255, 255, 0.5);
    }
  }

}

.txt_no_job {
  color: #323d47
}

.show_mob {
  display: none;
  @media only screen and (max-width: 991px) {
    display: block;
  }
}

.hidden_mob {
  display: block;
  @media only screen and (max-width: 991px) {
    display: none
  }
}

.nav-tabs-outline {
  .nav-link {
    border: 1px solid $blue;
    color: $blue;
    font-size: 1rem;

    &:hover, &.active {
      background-color: $blue;
    }
  }
}

.bg-blue {
  &.button--moema {
    &:hover {
      background-color: $blue;
    }
  }
}

hr:last-of-type {
  display: none;
}

.client-card {
  .card-body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;

    p {
      text-align: center
    }
  }
}

.timeline-item {
  &:nth-child(even), &.left {
    margin-left: initial;
    padding-left: 0;
    padding-right: 2.5rem;
    text-align: right;
    @media (max-width: 767.98px) {
      padding-left: 2.5rem;
      padding-right: 0;
      text-align: left;
    }
  }

  &:nth-child(even)::before, &.left::before {
    right: -8px;
    left: initial;
    @media (max-width: 767.98px) {
      left: 1px;
    }
  }

  &.right {
    margin-left: 50%;
    padding-left: 2.5rem;
    padding-right: 0;
    text-align: left;
    @media (max-width: 767.98px) {
      margin-left: 0;
    }
  }

  &.right::before {
    right: auto;
    left: -8px;
    @media (max-width: 767.98px) {
      left: 1px;
    }
  }
}

.single_carreer {
  margin-top: 133px;

  &[data-overlay]::before, [data-overlay-light]::before {
    background: rgb(0, 62, 76);
  }

  @media only screen and (max-width: 991px) {
    margin-top: 108px;
  }
}

.card-body-candidate-list {
  .fixheight-body {
    p {
      color: #626262;

      span {
        font-weight: bold;
      }

      small {
        font-size: 12px;
        color: rgba(98, 98, 98, 0.5);
      }
    }
  }
}

.secteur-candidate {
  background: #EFEFEF;
  border-radius: 20px;
  color: #626262;
  padding: 5px 10px;
}

.candidate_vedette {
  background: #0B809A;
  box-shadow: 0 4px 4px rgba(50, 50, 71, 0.08), 0 4px 8px rgba(50, 50, 71, 0.06);
  border-radius: 20px;
  padding: 8px 15px;

  p {
    color: #FFFFFF;
    font-size: 13px;
    line-height: 15px;

    &:before {
      background: url("../../../img/icons/vedette-icon.png") center no-repeat;
      background-size: 18px 18px !important;
      content: "";
      width: 20px;
      height: 20px;
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      margin-right: 5px;
    }
  }
}

.single_candidate {
  margin-top: 133px;
  padding-top: 80px !important;
  padding-bottom: 80px !important;

  &[data-overlay]::before, [data-overlay-light]::before {
    background: rgb(0, 0, 0);
  }

  @media only screen and (max-width: 991px) {
    margin-top: 108px;
  }

  .single_candidate_surtitle {
    font-size: 1.8em;
    margin-bottom: 0;
  }

}

.job_desc {
  h5 {
    padding-top: 20px;
  }
}

.recruiter {
  .card-body {
    margin-top: -18%;
    text-align: center;
    @media (max-width: 991px) {
      margin-top: initial;
      .text-white {
        color: #757575 !important;
      }
    }

    p {
      margin-bottom: 5px;
    }
  }
}

.team_wrapper {
  justify-content: center;

  .body-bio {
    p {
      font-size: .85em;
    }
  }

  .modal-dialog {
    max-width: 432px;
  }
}

.validation_message {
  margin-bottom: 12px;
  font-style: italic;
  font-size: 12px;
  color: #ff9000;
  background: #fcffa2;
  color: #c70d24;
  padding-left: 7px;
}

.validation_error {
  color: #e2021e !important;
  margin-bottom: 12px;
  font-size: 12px;
  line-height: 16px;
  background: #b5b4b4;
  padding: 7px;
  border-left: 2px #e2021e solid;
}

header:not(.about-page), footer {
  background-color: transparent !important;
  height: auto !important;
}

.gform_confirmation_wrapper {
  text-align: center;
  color: $green;
}

.v-hidden {
  visibility: hidden;
}

.about-page {
  .videoContainer {
    position: absolute;
    top: 0;
    width: 100%;
    /*height: 100%;*/
    min-height: 100%;
    overflow: hidden;
    /*background-attachment: scroll;*/
    video {
      min-width: 100%;
      min-height: 100%;
      @media (max-width: 650px) {
        max-width: none;
        height: 100%;
      }
    }

    .videoOverlay {
      background: #000;
      opacity: 0.6;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
    }
  }

  .text-banner {
    position: relative;
    z-index: 1
  }
}

#variations {
  &.section {
    @media (max-width: 767px) {
      padding-bottom: 0rem;
      &:last-child {
        padding-bottom: 0rem;
      }
    }
  }
}

.services-no {
  font-family: 'Muli', sans-serif;
  color: $blue;
  line-height: 1em;
}

.hide_section {
  display: none;
}

/*animate sections AOS - page client*/
.bullet-list {
  hr.my-8 {
    @media screen and (max-width: 991px) {
      margin: 3rem auto 2rem !important;
    }
  }
}

/*animation hover - page candidat*/
/* Wobble Vertical */
@-webkit-keyframes hvr-wobble-vertical {
  16.65% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  33.3% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes hvr-wobble-vertical {
  16.65% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  33.3% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.hvr-wobble-vertical {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hvr-wobble-vertical:hover, .hvr-wobble-vertical:focus, .hvr-wobble-vertical:active {
  -webkit-animation-name: hvr-wobble-vertical;
  animation-name: hvr-wobble-vertical;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.image-emploi {
  background-size: cover;
  width: 100%;
  height: 204px;
  background-position: center;
  background-repeat: no-repeat;
}
